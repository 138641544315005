import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import { accountService } from './app/_services';
import "./i18n";
import * as serviceWorker from './serviceWorker';

accountService.refreshToken().finally(startApp);
if (process.env.NODE_ENV === 'production') {
  // Override console methods
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
}
function startApp() {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
    , document.getElementById('root')
  );
}


serviceWorker.unregister();