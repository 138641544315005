import React, { useEffect, useState } from "react"
import CoreCustom from "./CustomFields/CoreCustom"

import SplitCustom from "./CustomFields/SplitCustom"

import MultiCustom from "./CustomFields/MultiCustom"

import { KeyboardArrowRightSharp, KeyboardArrowDownSharp, Edit, Close, Check } from '@mui/icons-material'
export const CustomFields = ({ customFields, setCustomFieldsMetaData, customFieldsMetaData, isLoading, setIsLoading, formikRef,
    changeCustomFields, setCustomFieldStructure, setFilledCustomFields, filledCustomFields, isAddMode, secondaryNames, setSecondaryNames }) => {
    // console.log("CustomFields 8 customFields cod1", customFields, filledCustomFields,
    //     customFields.map(customField => filledCustomFields.length > 0 && Object.entries(filledCustomFields[0]).filter(filledFields => {
    //         return filledFields[0].startsWith(customField.mapName)
    //     }
    //     )), customFieldsMetaData)
    console.log("filledCustomFields in custom Fields cod1", filledCustomFields)
    const [isOpen, setIsOpen] = useState({})
    const [searchKey, setSearchKey] = useState('')
    const [secondaryNameStates, setSecondaryNameStates] = useState({})
    useEffect(() => {
        console.log('29032024 secondaryNames', secondaryNames)
        setSecondaryNameStates((prevVal) => {
            const secondaryNameFieldsStatus = {}
            Object.entries(secondaryNames || {}).forEach(secondaryName => {
                secondaryNameFieldsStatus[secondaryName[0]] = {}
                secondaryNameFieldsStatus[secondaryName[0]].isEditing = false
                secondaryNameFieldsStatus[secondaryName[0]].value = secondaryName[1] || ''
                secondaryNameFieldsStatus[secondaryName[0]].originalValue = secondaryName[1] || ''
            })
            console.log('customfields 29032024 ', secondaryNameFieldsStatus, secondaryNames)
            return secondaryNameFieldsStatus
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryNames])
    const setFieldExpansion = (index, shouldOpen) => {
        setIsOpen(currentState => { return { ...currentState, [index]: shouldOpen } })
    }
    const handleSearch = (e) => {
        setSearchKey(e.target.value)
    }

    const updateSecondaryName = (secondaryNameKey) => {
        console.log(secondaryNameKey)
        setSecondaryNames((prevVal) => { })
        // formikRef.current.setFieldValue('WorldImageURL', data.url, false)
    }
    return (
        <div>
            <input type="text" className="form-control border-0" style={{ "boxShadow": '#63636333 0px 2px 8px 0px' }}
                placeholder={"Search Field"} onChange={e => handleSearch(e)} />
            {
                customFields && (
                    customFields.filter(customField => !String(searchKey)?.trim() ? customField :
                        customField.mapName.toLowerCase().includes(String(searchKey)?.trim().toLowerCase())).sort((firstField, secondField) => {
                            const firstFieldMapNameInLowerCase = firstField.mapName.toLowerCase();
                            const secondFieldMapNameInLowerCase = secondField.mapName.toLowerCase();
                            if (firstFieldMapNameInLowerCase < secondFieldMapNameInLowerCase) return -1;
                            if (firstFieldMapNameInLowerCase > secondFieldMapNameInLowerCase) return 1;
                            return 0;
                        }).map((customField, index) => (
                            customField.fType == 'SPLIT' &&
                            <div style={{
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", margin: '16px 0px', borderRadius: '8px',
                                padding: '5px', height: isOpen[index] ? '100%' : '50px'
                            }}>
                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    {isOpen[index] ? <KeyboardArrowDownSharp style={{ display: 'inline', fontSize: '30px' }} onClick={() => setFieldExpansion(index, false)} /> :
                                        <KeyboardArrowRightSharp style={{ display: 'inline', fontSize: '30px' }} onClick={() => setFieldExpansion(index, true)} />}
                                    <h3 style={{ display: 'inline' }}>{customField.mapName}|</h3>
                                    <h4>Personalised Name:</h4>
                                    {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing ? <input id={customField.mapName} type="text" className="form-control border-0 m-0 p-0 pl-2 mh-0" style={{ width: '200px', fontSize: '1.65rem' }}
                                        placeholder={"Personalized Field Name"} value={secondaryNameStates[customField.mapName]?.value || ''} onChange={e => {
                                            console.log('29032024 onChangeInput outside', e.target?.value)
                                            const targetValue = e.target?.value
                                            setSecondaryNameStates((prevVal) => {
                                                console.log('29032024 onChangeInput ', targetValue, prevVal[customField.mapName]?.value)
                                                return {
                                                    ...prevVal, [customField.mapName]: {
                                                        ...prevVal[customField.mapName],
                                                        value: targetValue
                                                    }
                                                }
                                            })
                                        }} /> : <h3>{secondaryNameStates[customField.mapName]?.value || ''}</h3>}
                                    {!isAddMode && !secondaryNameStates[customField.mapName]?.isEditing &&
                                        <Edit style={{ display: 'inline', fontSize: '30px' }} onClick={() => {
                                            console.log('29032024 Edit')
                                            setSecondaryNameStates((prevVal) => { return { ...prevVal, [customField.mapName]: { ...prevVal[customField.mapName], isEditing: true } } })
                                        }} />}
                                    {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing &&
                                        <Close style={{ display: 'inline', fontSize: '30px' }}
                                            onClick={() => {
                                                setSecondaryNameStates((prevVal) => {
                                                    return {
                                                        ...prevVal, [customField.mapName]: {
                                                            ...prevVal[customField.mapName],
                                                            value: prevVal[customField.mapName].originalValue, isEditing: false
                                                        }
                                                    }
                                                })
                                                setSecondaryNames(prevVal => {
                                                    console.log('29032024 setSecondaryNames ', secondaryNameStates[customField.mapName]?.value || '')
                                                    return {
                                                        ...prevVal, [customField.mapName]: secondaryNameStates[customField.mapName].originalValue
                                                    }
                                                })
                                            }} />}
                                    {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing &&
                                        <Check style={{ display: 'inline', fontSize: '30px' }}
                                            onClick={() => {
                                                setSecondaryNameStates((prevVal) => {
                                                    return {
                                                        ...prevVal, [customField.mapName]: {
                                                            ...prevVal[customField.mapName],
                                                            value: prevVal[customField.mapName].value, isEditing: false
                                                        }
                                                    }
                                                })
                                                setSecondaryNames(prevVal => {
                                                    console.log('29032024 setSecondaryNames ', secondaryNameStates[customField.mapName]?.value || '')
                                                    return {
                                                        ...prevVal, [customField.mapName]: secondaryNameStates[customField.mapName]?.value || ''
                                                    }
                                                })
                                            }} />}
                                </div>
                                <div style={{ visibility: isOpen[index] ? 'visible' : 'hidden' }}>
                                    <SplitCustom
                                        CF={customField}
                                        changeCustomFields={changeCustomFields}
                                        setCustomFieldStructure={setCustomFieldStructure}
                                        formikRef={formikRef}
                                        key={customField.fieldName}
                                        mapName={customField.mapName}
                                        setFilledCustomFields={setFilledCustomFields}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        filledCustomFields={filledCustomFields.length > 0 && Object.entries(filledCustomFields[0]).filter(filledFields => filledFields[0] == customField.mapName)[0]}
                                    /></div>
                            </div>
                            ||
                            customField.fType == 'MULTI' &&
                            <div style={{
                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", margin: '16px 0px',
                                borderRadius: '8px', padding: '5px', height: isOpen[index] ? '100%' : '50px'
                            }}>
                                {/* filledCustomFieldssearch{filledCustomFields.length > 0 && JSON.stringify(
                                Object.entries(filledCustomFields[0]).filter(filledFields => {
                                    return filledFields[0].startsWith(customField.mapName)
                                })
                            )}
                            <br />
                            <br />
                            <br />
                            filledCustomFields{filledCustomFields && JSON.stringify(
                                filledCustomFields
                            )}
                            <br />
                            <br />
                            <br />
                            customField {JSON.stringify(
                                customField
                            )}
                            <br />
                            <br />
                            <br /> */}
                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    {isOpen[index] ? <KeyboardArrowDownSharp style={{ display: 'inline', fontSize: '30px' }} onClick={() => setFieldExpansion(index, false)} /> :
                                        <KeyboardArrowRightSharp style={{ display: 'inline', fontSize: '30px' }} onClick={() => setFieldExpansion(index, true)} />}
                                    <h3 style={{ display: 'inline' }}>{customField.mapName}|</h3>
                                    <h4>Personalised Name:</h4>
                                    {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing ? <input id={customField.mapName} type="text" className="form-control border-0 m-0 p-0 pl-2 mh-0" style={{ width: '200px', fontSize: '1.65rem' }}
                                        placeholder={"Personalized Field Name"} value={secondaryNameStates[customField.mapName]?.value || ''} onChange={e => {
                                            console.log('29032024 onChangeInput outside', e.target?.value)
                                            const targetValue = e.target?.value
                                            setSecondaryNameStates((prevVal) => {
                                                console.log('29032024 onChangeInput ', targetValue, prevVal[customField.mapName]?.value)
                                                return {
                                                    ...prevVal, [customField.mapName]: {
                                                        ...prevVal[customField.mapName],
                                                        value: targetValue
                                                    }
                                                }
                                            })
                                        }} /> : <h3>{secondaryNameStates[customField.mapName]?.value || ''}</h3>}
                                    {!isAddMode && !secondaryNameStates[customField.mapName]?.isEditing &&
                                        <Edit style={{ display: 'inline', fontSize: '30px' }} onClick={() => {
                                            console.log('29032024 Edit')
                                            setSecondaryNameStates((prevVal) => { return { ...prevVal, [customField.mapName]: { ...prevVal[customField.mapName], isEditing: true } } })
                                        }} />}
                                    {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing &&
                                        <Close style={{ display: 'inline', fontSize: '30px' }}
                                            onClick={() => {
                                                setSecondaryNameStates((prevVal) => {
                                                    return {
                                                        ...prevVal, [customField.mapName]: {
                                                            ...prevVal[customField.mapName],
                                                            value: prevVal[customField.mapName].originalValue, isEditing: false
                                                        }
                                                    }
                                                })
                                                setSecondaryNames(prevVal => {
                                                    return {
                                                        ...prevVal, [customField.mapName]: secondaryNameStates[customField.mapName].originalValue
                                                    }
                                                })
                                            }} />}
                                    {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing &&
                                        <Check style={{ display: 'inline', fontSize: '30px' }}
                                            onClick={() => {
                                                setSecondaryNameStates((prevVal) => {
                                                    return {
                                                        ...prevVal, [customField.mapName]: {
                                                            ...prevVal[customField.mapName],
                                                            value: prevVal[customField.mapName].value, isEditing: false
                                                        }
                                                    }
                                                })
                                                setSecondaryNames(prevVal => {
                                                    console.log('29032024 setSecondaryNames ', secondaryNameStates[customField.mapName]?.value || '')
                                                    return {
                                                        ...prevVal, [customField.mapName]: secondaryNameStates[customField.mapName]?.value || ''
                                                    }
                                                })
                                            }} />}
                                </div>
                                <div style={{ visibility: isOpen[index] ? 'visible' : 'hidden' }}>
                                    <MultiCustom
                                        isLoading={isLoading}
                                        setCustomFieldsMetaData={setCustomFieldsMetaData}
                                        setIsLoading={setIsLoading}
                                        CF={customField}
                                        formikRef={formikRef}
                                        changeCustomFields={changeCustomFields}
                                        setCustomFieldStructure={setCustomFieldStructure}
                                        setFilledCustomFields={setFilledCustomFields}
                                        mapName={customField.mapName}
                                        key={customField.fieldName}
                                        customFields={customFields}
                                        filledCustomFields={filledCustomFields.length > 0 && Object.entries(filledCustomFields[0]).filter(filledFields => {
                                            return filledFields[0].startsWith(customField.mapName)
                                        }
                                        )}
                                    />
                                </div>
                            </div>
                            ||
                            (customField.fType == 'CORE' &&
                                <div style={{
                                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", margin: '16px 0px',
                                    borderRadius: '8px', padding: '5px', height: isOpen[index] ? '100%' : '50px'
                                }}>
                                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        {isOpen[index] ? <KeyboardArrowDownSharp style={{ display: 'inline', fontSize: '30px' }} onClick={() => setFieldExpansion(index, false)} /> :
                                            <KeyboardArrowRightSharp style={{ display: 'inline', fontSize: '30px' }} onClick={() => setFieldExpansion(index, true)} />}
                                        <h3 style={{ display: 'inline' }}>{customField.mapName}|</h3>
                                        <h4>Personalised Name:</h4>
                                        {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing ? <input id={customField.mapName} type="text" className="form-control border-0 m-0 p-0 pl-2 mh-0" style={{ width: '200px', fontSize: '1.65rem' }}
                                            placeholder={"Personalized Field Name"} value={secondaryNameStates[customField.mapName]?.value || ''} onChange={e => {
                                                console.log('29032024 onChangeInput outside', e.target?.value)
                                                const targetValue = e.target?.value
                                                setSecondaryNameStates((prevVal) => {
                                                    console.log('29032024 onChangeInput ', targetValue, prevVal[customField.mapName]?.value)
                                                    return {
                                                        ...prevVal, [customField.mapName]: {
                                                            ...prevVal[customField.mapName],
                                                            value: targetValue
                                                        }
                                                    }
                                                })
                                            }} /> : <h3>{secondaryNameStates[customField.mapName]?.value || ''}</h3>}
                                        {!isAddMode && !secondaryNameStates[customField.mapName]?.isEditing &&
                                            <Edit style={{ display: 'inline', fontSize: '30px' }} onClick={() => {
                                                console.log('29032024 Edit')
                                                setSecondaryNameStates((prevVal) => { return { ...prevVal, [customField.mapName]: { ...prevVal[customField.mapName], isEditing: true } } })
                                            }} />}
                                        {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing &&
                                            <Close style={{ display: 'inline', fontSize: '30px' }}
                                                onClick={() => {
                                                    setSecondaryNameStates((prevVal) => {
                                                        return {
                                                            ...prevVal, [customField.mapName]: {
                                                                ...prevVal[customField.mapName],
                                                                value: prevVal[customField.mapName].originalValue, isEditing: false
                                                            }
                                                        }
                                                    })
                                                    setSecondaryNames(prevVal => {
                                                        console.log('29032024 setSecondaryNames ', secondaryNameStates[customField.mapName]?.value || '')
                                                        return {
                                                            ...prevVal, [customField.mapName]: secondaryNameStates[customField.mapName].originalValue
                                                        }
                                                    })
                                                }} />}
                                        {!isAddMode && secondaryNameStates[customField.mapName]?.isEditing &&
                                            <Check style={{ display: 'inline', fontSize: '30px' }}
                                                onClick={() => {
                                                    setSecondaryNameStates((prevVal) => {
                                                        return {
                                                            ...prevVal, [customField.mapName]: {
                                                                ...prevVal[customField.mapName],
                                                                value: prevVal[customField.mapName].value, isEditing: false
                                                            }
                                                        }
                                                    })
                                                    setSecondaryNames(prevVal => {
                                                        console.log('29032024 setSecondaryNames ', secondaryNameStates[customField.mapName]?.value || '')
                                                        return {
                                                            ...prevVal, [customField.mapName]: secondaryNameStates[customField.mapName]?.value || ''
                                                        }
                                                    })
                                                }} />}
                                    </div>
                                    <div style={{ visibility: isOpen[index] ? 'visible' : 'hidden' }}>
                                        <CoreCustom
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            customField={[customField.mapName, customField.fieldName]}
                                            formikRef={formikRef}
                                            changeCustomFields={changeCustomFields}
                                            key={customField.fieldName}
                                            mapName={customField.mapName}
                                            setFilledCustomFields={setFilledCustomFields}
                                            filledCustomFields={filledCustomFields.length > 0 && Object.entries(filledCustomFields[0]).filter(filledFields => filledFields[0] == customField.mapName)[0]}
                                        //friendlyName={CF.friendlyName}
                                        />
                                    </div>
                                </div>
                            )
                        )
                        )

                )
            }
        </div>
    )
}