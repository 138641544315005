import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService, alertService } from '../../_services';

import SplitCustom from './SplitCustom';
import CoreCustom from './CoreCustom';
import _ from 'lodash';

const MultiCustom = ({ isLoading, setCustomFieldsMetaData, setIsLoading, CF, changeCustomFields, setCustomFieldStructure, customFields, formikRef, CFData, mapName, formikData, filledCustomFields, setFilledCustomFields }) => {
  // console.log("Multi Fields", filledCustomFields)
  // console.log("Multi Fields CF", CF)
  useEffect(() => { console.log("MultiCustom:::: filledCustomField codex", filledCustomFields) }, [filledCustomFields])
  const [configField, setConfigField] = useState([])
  const [configFieldRepeat, setConfigFieldRepeat] = useState([])
  const [deletedFields, setDeletedFields] = useState([])
  // const checkData = filledCustomFields.map(mf=>{
  //     if(mf[0].split('_')[1].toLowerCase() == 'config'){
  //         setConfigField(mf)
  //     } else {
  //         setConfigFieldRepeat(prevState=> {
  //             return {...prevState, ...mf}
  //         })
  //     } 
  // })
  const [multiFieldsData, setMultiFieldsData] = useState([])
  const [multiFieldsRepeatData, setMultiFieldsRepeatData] = useState([])
  const [customMultiFields, setCustomMultiFields] = useState([])
  const [customMultiFieldsRepeat, setCustomMultiFieldsRepeat] = useState([])
  useEffect(() => {
    Object.entries(CF.multiFields).map(cf => {
      // console.log("useEffect 29 cod1 multiFields", cf)
      accountService.getCustomFields(cf[1]).then(fields => {
        setCustomFieldsMetaData((prevState => {
          return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiFields }]
        }))

        setCustomMultiFields(prevState => {
          return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiFields }]
        })
      })
    })
    // console.log("useEffect 29 cod1 multiFields", CF, customFields, filledCustomFields)
    Object.entries(CF.multiRepeat).map((cf, index) => {
      accountService.getCustomFields(cf[1]).then(fields => {
        setCustomFieldsMetaData((prevState => {
          return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiFields }]
        }))

        setCustomMultiFieldsRepeat(prevState => {
          console.log('setCustomMultiFieldsRepeat::: ', groupAndSort([...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiRepeat }]))
          return groupAndSort([...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiRepeat }])
        })
      })
    })
  }, [])

  useEffect(() => {
    console.log("MultiCustom useEffect 45", filledCustomFields, customMultiFieldsRepeat.length, Object.entries(CF.multiRepeat).length)
    if (filledCustomFields.length > 0 && (customMultiFieldsRepeat.length == Object.entries(CF.multiRepeat).length)) {
      const customFilledFields = [];
      filledCustomFields.map(fcf => {
        if (fcf[0].split('_').length > 1 && fcf[0].split('_')[1].toLowerCase() == 'config') {
          setMultiFieldsData(fcf)
        }
        else {
          console.log('fcf[1]fcf[1]::: ', fcf[1])
          if (fcf[1] && Object.entries(fcf[1]).length > 1) {
            const creatingCustomRepeat = customMultiFieldsRepeat.filter(mfp => mfp.fieldName == (fcf[0].split('_').length > 1 && (fcf[0].split('_')[0] === 'Vote' ? 'pollElement' : fcf[0].split('_')[1]))).length > 0 && customMultiFieldsRepeat.filter(mfp => mfp.fieldName == (fcf[0].split('_')[0] === 'Vote' ? 'pollElement' : fcf[0].split('_')[1]))[0] || []
            console.log('creatingCustomRepeat useEffect 67', customMultiFieldsRepeat, creatingCustomRepeat)
            if (Object.entries(creatingCustomRepeat).length > 0) {
              const newObj = {
                "multiFields": {},
                "splitFields": creatingCustomRepeat.splitFields,
                "fType": creatingCustomRepeat.fType,
                "prefix": "<<ElementType>>" + fcf[0].split('_')[0],
                "splitRepeat": creatingCustomRepeat.splitRepeat,
                "multiRepeat": creatingCustomRepeat.multiRepeat,
                "fieldName": fcf[0].split('_').length > 2 && fcf[0].split('_')[1] + '_' + fcf[0].split('_')[2] || fcf[0].split('_')[1],
                "friendlyName": fcf[0],
                "mapName": creatingCustomRepeat.mapName,
                "keyName": {
                  [creatingCustomRepeat.mapName]: creatingCustomRepeat.fieldName
                },
                "filledData": fcf
              }
              customFilledFields.push(newObj)
            }
          }
        }
      })
      console.log("setCustomMultiFieldsRepeat::: customFilledFields MultiCustom 75 cod1", groupAndSort(customFilledFields))
      if (groupAndSort(customFilledFields) && groupAndSort(customFilledFields).length)
        setCustomMultiFieldsRepeat(groupAndSort(customFilledFields))
    }
  }, [(customMultiFieldsRepeat.length == Object.entries(CF.multiRepeat).length), filledCustomFields])
  useEffect(() => {
    if (filledCustomFields.length > 0) {
      console.log("filledCustomFields MultiCustom length cod1 93", filledCustomFields)
      filledCustomFields.map(fcf => {
        console.log("filledCustomFields MultiCustom length cod1 95", fcf[0], fcf[0]?.split('_'))
        if (fcf[0].split('_').length > 1 && fcf[0].split('_')[1].toLowerCase() == 'config') {
          setMultiFieldsData(fcf)
        }
        if (fcf[0].split('_').length > 1 && fcf[0].split('_')[0] == 'Vote') {
          setMultiFieldsData(fcf)
        }
      })
    }
  }, [customMultiFields.length > 0, filledCustomFields])

  useEffect(() => {
    if (customMultiFields.length > 0) {
      console.log("customMultiFields", customMultiFields)
    }
  }, [customMultiFields])

  useEffect(() => {
    if (configFieldRepeat.length > 0) {
      console.log("Config Fields data with repeat", configFieldRepeat)
    }
  }, [configFieldRepeat])

  useEffect(() => {
    if (multiFieldsData) {
      console.log("customFields multiFieldsData", multiFieldsData)
    }
  }, [multiFieldsData])

  useEffect(() => {
    if (customMultiFieldsRepeat) {
      // console.log("customFields multi repeat", customMultiFieldsRepeat)
    }
  }, [customMultiFieldsRepeat])

  // useEffect(() => {
  //     console.log("MultiCustom 114 useEffect filledCustomFields cod1", filledCustomFields)
  // }, [filledCustomFields])


  const repeatFields = (e, type, CF) => {
    console.log('repeatFieldsrepeatFieldsrepeatFields::: ', filledCustomFields, filledCustomFields.find(e => e[0] === mapName + '_' + CF.fieldName))
    const fieldsOfType = customMultiFieldsRepeat.filter(
      (field) => field?.mapName === CF?.mapName
    );

    const length = customMultiFieldsRepeat.length
    const clone = _.cloneDeep(CF);
    const name = clone.prefix.split(">>")[1] != null ? clone.prefix.split(">>")[1] : clone.fieldName
    console.log("addcycle MultiCustom:::: filledCustomFields", filledCustomFields)
    e.preventDefault();
    if (type === 'split') {
      console.log("addcycle MultiCustom:::: CF", CF)
      console.log('addcycle clone.filledData::: ', clone, '  ', clone.filledData)
      clone.fieldName = (CF.fieldName + '_' + length);
      clone.friendlyName = CF.friendlyName + '_' + length;
      clone.mapName = CF.mapName;
      if (clone.filledData[1]) clone.filledData[1].Name = CF.mapName + (fieldsOfType.length + 1)
      // console.log("addcycle MultiCustom:::: CF", clone.filledData[1], clone.filledData[1].Name, fieldsOfType.length)
      // console.log("MultiCustom:::: repeatFields customFields cod1", customFields)
      // console.log("MultiCustom:::: repeatFields filledCustomFields cod1", filledCustomFields)
      // console.log("MultiCustom:::: repeatFields clone cod1", clone)
      // console.log("MultiCustom:::: repeatFields CF cod1", CF)
      let filledData = {}
      if ('filledData' in clone) {
        filledData = clone.filledData[1]

      } else {
        filledData['ElementType'] = clone.prefix.split('>')[1]
        filledData['PlayType'] = 'Stream'
        for (let field in clone.splitFields) {
          if (field === 'PlayType') continue
          filledData[field] = clone.splitFields[field] === 'BOOLEAN' ? false : ''
        }
      }

      setFilledCustomFields((prevState) => {
        const prevStateCloned = _.cloneDeep(prevState)
        prevStateCloned[0][clone.friendlyName] = filledData
        console.log('addcycle MultiCustom:::: repeatFields filledData cod1 setFilledCustomFields() 1', prevStateCloned)
        console.log("addcycle MultiCustom:::: repeatFields filledData cod1 setFilledCustomFields() 2", [...prevStateCloned, [clone.friendlyName, filledData]])
        return [...prevStateCloned, [clone.friendlyName, filledData]]
      })
      setCustomMultiFieldsRepeat(prevState => {
        console.log("setCustomMultiFieldsRepeat::: addcycle MultiCustom:::: setCustomMultiFieldsRepeat cod1", [...prevState, clone])
        return [...prevState, clone]
      })


    }
    if (type == 'text') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'boolean') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'enum') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'worldid') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'imagelink') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'filelink') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'pdflink') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'color') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
    if (type == 'datetime') {
      clone.fieldName = (name + "_Config_" + length).toLowerCase();
      clone.friendlyName = name + '_Config ' + length;
      setCustomMultiFieldsRepeat(prevState => {
        return [...prevState, clone]
      })


    }
  }

  const deleteField = (e, index, mapName, friendlyName) => {
    e.preventDefault()
    setDeletedFields((prevDeletedFields) => [...prevDeletedFields, friendlyName])
    console.log('addandelete deleteField:: mapName, friendlyName ', mapName, friendlyName)
    console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> filledCustomFields', filledCustomFields)
    // Filter customMultiFieldsRepeat to get only those of the specified mapName
    const fieldsOfType = customMultiFieldsRepeat.filter(
      (field) => field.mapName === mapName
    );

    // Only allow deletion if there are multiple instances
    if (fieldsOfType.length > 1) {
      // Update the state with the new list
      console.log('addandelete deleteField:: before customMultiFieldsRepeat::: ', customMultiFieldsRepeat)
      console.log('addandelete deleteField:: after setFilledCustomFields::: customMultiFieldsRepeat::: ', customMultiFieldsRepeat.filter(
        (field, idx) => idx !== index
      ))
      console.log('addandelete deleteField:: before filledCustomFields::: ', filledCustomFields)
      setCustomMultiFieldsRepeat((prevCustomMultiFieldsRepeat) => {
        console.log('addandelete deleteField:: during filledCustomFields::: ', prevCustomMultiFieldsRepeat)
        console.log('addandelete deleteField:: during  after filledCustomFields::: ', prevCustomMultiFieldsRepeat.filter(field => field.friendlyName !== friendlyName))
        return prevCustomMultiFieldsRepeat.filter(field => field.friendlyName !== friendlyName)
      });

      // Optionally remove from filledCustomFields as well
      setFilledCustomFields((prevFilledCustomFields) => {
        const prevFilledCustomFieldsObject = _.cloneDeep(prevFilledCustomFields[0])
        const updatedDeletedFields = [...deletedFields, friendlyName]
        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> prevFilledCustomFields', prevFilledCustomFields)
        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> prevFilledCustomFieldsObject keys', Object.keys(prevFilledCustomFieldsObject))
        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> deletedFields', updatedDeletedFields)
        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> prevFilledCustomFieldsObject', prevFilledCustomFieldsObject)
        updatedDeletedFields.forEach(deletedField => {
          delete prevFilledCustomFieldsObject[deletedField]
        })
        // Object.keys(prevFilledCustomFieldsObject).forEach(key => {
        //   console.log('handling undefined key 0 ', key, prevFilledCustomFieldsObject[key], prevFilledCustomFields)
        //   // if (!prevFilledCustomFieldsObject[key]) {
        //   console.log('handling undefined key 1 ', prevFilledCustomFields[1])
        //   if (Array.isArray(prevFilledCustomFields[1])) {
        //     const fieldDetails = prevFilledCustomFields[1].find(field => field[0] === key)
        //     console.log('handling undefined key 2 ', fieldDetails)
        //     if (fieldDetails)
        //       prevFilledCustomFieldsObject[key] = fieldDetails
        //     // }
        //   }
        // })
        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> prevFilledCustomFieldsObject after deletion ', prevFilledCustomFieldsObject)
        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> keys', prevFilledCustomFields.slice(1).map(eachField => eachField[0]))
        const newlyAddedFields = prevFilledCustomFields.slice(1).filter(eachField => ![...deletedFields, friendlyName].includes(eachField[0]))

        console.log('setFilledCustomFields() addandelete deleteField:: setFilledCustomFields++++> updatedValue ', [prevFilledCustomFieldsObject, newlyAddedFields])
        return [prevFilledCustomFieldsObject, newlyAddedFields]
      });
    }
  };

  const combineArrays = (arr1, arr2) => {
    const maxLength = Math.max(arr1.length, arr2.length);  // Find the length of the longer array
    const result = [];

    for (let i = 0; i < maxLength; i++) {
      if (i < arr1.length) result.push(arr1[i]);  // Add element from arr1 if it exists
      if (i < arr2.length) result.push(arr2[i]);  // Add element from arr2 if it exists
    }

    return result;
  }

  const groupAndSort = function (customMultiFieldsRepeat) {
    if (customMultiFieldsRepeat.length <= 1) return customMultiFieldsRepeat

    if (customMultiFieldsRepeat[0].fieldName.includes('playlist')) {
      console.log('customMultiFieldsRepeatClone::: ', customMultiFieldsRepeat)
      const video = customMultiFieldsRepeat.filter(field => field.friendlyName.toLowerCase().includes('video')).sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
      const presentation = customMultiFieldsRepeat.filter(field => field.friendlyName.toLowerCase().includes('presentation')).sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
      console.log('customMultiFieldsRepeatClone::: sorted', customMultiFieldsRepeat.filter(field => field.friendlyName.toLowerCase().includes('video')),
        video, customMultiFieldsRepeat.filter(field => field.friendlyName.toLowerCase().includes('presentation')), presentation,)
      console.log('customMultiFieldsRepeatClone::: combined', combineArrays(video, presentation))
      return combineArrays(video, presentation)
    }
    if (customMultiFieldsRepeat[0].fieldName.includes('poll')) {
      return customMultiFieldsRepeat.sort().reverse()
    }

  }
  return (
    <>
      <div
      // style={{"border" :"2px solid blue"}}
      >
        {/* <h1>Custom Muti Fields</h1> */}
        <br /><br />
        {/* <p>Custom Muti Fields config---------------------</p> */}
        <h4>{CF.friendlyName}</h4>
        <div className="form-group col-12">
          {/* {JSON.stringify(customMultiFields)} */}
          {/* {filledCustomFields && JSON.stringify(filledCustomFields)} */}
          <div className="card bg-light">
            {
              customMultiFields.length > 0 && (
                customMultiFields.map(multiFieldsNoRepeat => (

                  multiFieldsNoRepeat.fType == 'SPLIT' &&
                  <>
                    <SplitCustom setIsLoading={setIsLoading} isLoading={isLoading} CF={multiFieldsNoRepeat} setCustomFieldStructure={setCustomFieldStructure} formikRef={formikRef} changeCustomFields={changeCustomFields} key={CF.fieldName} mapName={mapName + '_Config'} keyName={multiFieldsNoRepeat.keyName} filledCustomFields={multiFieldsData.length > 0 && multiFieldsData}
                    // CFData={CFData}
                    // CFData={CFData.filter(cf=>{
                    //     const validString = (cf[0].split('_') == null ? cf[0] : cf[0].split('_')[1]).indexOf(multiFieldsNoRepeat.mapName) != -1
                    //     if(validString){
                    //         return cf
                    //     }
                    //     }
                    //     )[0]}
                    />
                    <hr />
                  </>
                  ||
                  multiFieldsNoRepeat.fType == 'CORE' &&
                  <>
                    CORE<CoreCustom setIsLoading={setIsLoading} isLoading={isLoading} CF={multiFieldsNoRepeat} changeCustomFields={changeCustomFields} mapName={mapName + '_config'} keyName={multiFieldsNoRepeat.keyName} friendlyName={CF.friendlyName} filledCustomFields={multiFieldsData.length > 0 && multiFieldsData} />
                    <hr />
                  </>
                ))

              )
            }
          </div>
        </div>
        {/* <p>End Custom Muti Fields config---------------------</p> <br /><br /> */}
        <br /><br />
        <div
        // style={{"border":"4px solid green", "margin": "10px"}}
        >

          {/* <p>Custom Muti Fields Repeat config---------------------</p> */}
          <div className="form-group col-12">
            {/* {JSON.stringify(customMultiFieldsRepeat)} */}
            {/* {JSON.stringify(groupAndSort(customMultiFieldsRepeat))} */}
            <div className="card bg-light">
              {
                customMultiFieldsRepeat && customMultiFieldsRepeat.length > 0 && (
                  customMultiFieldsRepeat.map((multiFieldsRepeat, index) => {
                    const fieldsOfType = customMultiFieldsRepeat.filter(
                      (field) => field?.mapName === multiFieldsRepeat?.mapName
                    );
                    const fieldIndex =
                      fieldsOfType.findIndex(
                        (field) => field === multiFieldsRepeat
                      ); // +1 to start from 1 instead of 0

                    return (multiFieldsRepeat.fType == 'SPLIT' && <>
                      {/* {mapName + '_' + multiFieldsRepeat.fieldName}
                      {JSON.stringify(filledCustomFields)}
                      {JSON.stringify(Array.isArray(filledCustomFields) && filledCustomFields.find(e => e[0] === mapName + '_' + multiFieldsRepeat.fieldName))} */}
                      <div className='d-flex align-items-center justify-content-center'>
                        <h4>{multiFieldsRepeat.friendlyName}</h4>
                        {fieldIndex === 0 && (
                          <button
                            className="btn btn-link"
                            onClick={(e) => {
                              alertService.success(
                                `Add ${multiFieldsRepeat.mapName} successfully`,
                                {
                                  keepAfterRouteChange: true,
                                }
                              );
                              repeatFields(
                                e,
                                "split",
                                multiFieldsRepeat,
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="#198754"
                              class="bi bi-file-plus-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z" />
                            </svg>
                            Add{" "}
                            {multiFieldsRepeat.mapName === "Vote"
                              ? "Poll Question"
                              : multiFieldsRepeat.mapName}
                          </button>)}<br /> <br />
                        {fieldsOfType?.length > 1 && fieldIndex > 0 && (
                          <button
                            onClick={(e) =>
                              deleteField(
                                e,
                                index,
                                multiFieldsRepeat.mapName,
                                multiFieldsRepeat.friendlyName,
                              )
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                              background: "#fff",
                              padding: "4px",
                              borderRadius: "6px",
                              cursor: "pointer",
                              marginLeft: "10px",
                              boxShadow:
                                "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              paddingBottom: "8px"
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ width: "100%" }}
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#f80d0d"
                                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                      <SplitCustom setIsLoading={setIsLoading} isLoading={isLoading} formikRef={formikRef} setCustomFieldStructure={setCustomFieldStructure} CF={multiFieldsRepeat} changeCustomFields={changeCustomFields} key={CF.fieldName} mapName={mapName + '_' + multiFieldsRepeat.fieldName}
                        CFData={CFData} keyName={multiFieldsRepeat.keyName}
                        filledCustomFields={Array.isArray(filledCustomFields) && filledCustomFields.find(e => e[0] === mapName + '_' + multiFieldsRepeat.fieldName)}
                        inputDefaultValue={`${multiFieldsRepeat.mapName} ${Number(fieldIndex) + 1}`} setFilledCustomFields={setFilledCustomFields}
                      />
                      <hr />
                    </>
                      ||
                      multiFieldsRepeat.fType == 'CORE' &&
                      <>
                        <div className='d-flex align-items-center justify-content-center'>
                          <button className='btn btn-link col-4' onClick={e => repeatFields(e, 'split', multiFieldsRepeat)}>

                            Add {multiFieldsRepeat.mapName}</button>
                        </div>
                        <CoreCustom setIsLoading={setIsLoading} isLoading={isLoading} CF={multiFieldsRepeat} changeCustomFields={changeCustomFields} mapName={mapName + '_' + multiFieldsRepeat.fieldName} friendlyName={CF.friendlyName}
                          filledCustomFields={multiFieldsRepeat.filledData && multiFieldsRepeat.filledData.length > 0 && multiFieldsRepeat.filledData}
                          keyName={multiFieldsRepeat.keyName}
                        />
                        <hr />
                      </>

                    )
                  })

                )
              }
            </div>
          </div>

          {/* <p>End Custom Muti Fields Repeat config---------------------</p><br /><br /><br /> */}
        </div>
      </div>
    </>
  )
}
export default MultiCustom;