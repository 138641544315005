import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';

const TextCustom = ({ CF, changeCustomFields, innerKeyName, mapName, filledCustomFields, inputDefaultValue }) => {
    // mapName === 'PLAYZONE_left_playlistVideo' && console.log("Text Custom Map cod1", mapName, filledCustomFields, CF)
    const defaultValue =
        CF[0] === "AspectRatio"
            ? "1.77"
            : (filledCustomFields && filledCustomFields[1]) || inputDefaultValue;
    return (
        <>
            <div className="row">
                <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                    <label>
                        {CF[0]}
                        {/* {JSON.stringify(filledCustomFields)} */}
                    </label>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                    {/* Displaying filledCustomFields value for {CF[0]} : {JSON.stringify(filledCustomFields)} */}
                    <Field name={CF[0]} onChange={e => changeCustomFields({ target: { name: CF[0], value: e.target.value } }, CF[0], mapName)} type="text" className={'form-control'} value={(filledCustomFields && filledCustomFields[1]) || defaultValue} />
                </div>
            </div>
        </>
    )
}
export default TextCustom;