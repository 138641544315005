import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';
import LoadingIndicator from '../../../_components/LoadingIndicator';
const ImageLinkCustom = ({ isLoading, setIsLoading, CF, changeCustomFields, innerKeyName, mapName, filledCustomFields }) => {
    // //console.log("Image Link",CF)
    const [uploadedImage, setUploadedImage] = useState(filledCustomFields && filledCustomFields[1] || '')
    const [localFileUrl, setLocalFileUrl] = useState('')
    const [isImageLoading, setIsImageLoading] = useState(false)
    const fileUpload = (event, type) => {
        // setIsLoading(true)
        setIsImageLoading(true)
        const files = event.target.files[0];
        accountService
            .uploadFile(files)
            .then((data) => {
                console.log("17 cod1", "imagelinkcustom")
                setUploadedImage(data.url)
                console.log("18 cod1", "imagelinkcustom")
                changeCustomFields({ target: { name: CF[0], value: data.url } }, CF[0], mapName)
                console.log("20 cod1", "imagelinkcustom")
                setLocalFileUrl(data?.url)
                // setIsLoading(false)
                console.log("23 cod1", "imagelinkcustom")
                setIsImageLoading(false)
                console.log("26 cod1", "imagelinkcustom")
            });

    }

    // useEffect(()=>{
    //     if(textVal !=''){
    //         changeCustomFields({target:{name:customName['key'], value:textVal}}, mapName)
    //     }

    // },[textVal != ''])
    return (
        <>
            <div className="row">
                <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                    <label>
                        {CF[0]}
                    </label>
                </div>
                <div className="col-4 d-flex flex-column align-items-center justify-content-center ">
                    <div style={{ width: '100%' }}>
                        <Field name={CF[0]} onChange={e => changeCustomFields({ target: { name: CF[0], value: e.target.value } }, CF[0], mapName)} type="text" className={'form-control'} value={filledCustomFields && filledCustomFields[1] || localFileUrl && localFileUrl || ''} />
                    </div>
                    <div>
                        {!isImageLoading && <Field name={CF[0]} type="file" value={undefined} className={'form-control'} onChange={e => fileUpload(e, CF[0])} single="true" />}
                    </div>
                </div>
                <div className="col-2 d-flex align-items-start justify-content-start" style={{ width: "300px", height: "200px", overflow: "hidden" }}>
                    {uploadedImage && <img src={uploadedImage} style={{ "width": "100%", height: "100%", 'object-fit': "contain" }} alt='thumbnail' />}
                </div>
                {isImageLoading && <div className="offset-4 col-4 d-flex align-items-center justify-content-center">

                    <LoadingIndicator />


                </div>}

            </div>
        </>
    )
}


export default ImageLinkCustom;