import { ButtonToolbar, ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import PakDownloadsArray from './pakDownloadsRequired';
import DownloadsArray from './downloadsRequired';
import CustomAdminsArray from './customAdmins';
import { Alert } from '../_components';

import _ from 'lodash'
import { CustomFields } from './CustomFields';
import replaceNthOccurrence from '../../utils/replaceNthOccurrence'
const AddWorld = ({ history, match }) => {
    const { id } = match.params;
    const user = accountService.userValue;
    const [selectedMap, setSelectedMap] = useState({})
    const [downloads, setDownloads] = useState([])
    const [pakDownloads, setPakDownloads] = useState([])

    const [customAdmin, setCustomAdmin] = useState([])
    const [customFieldStructure, setCustomFieldStructure] = useState([])
    const [customFieldStructureData, setCustomFieldStructureData] = useState([])
    const [filledCustomFields, setFilledCustomFields] = useState([])
    const [allItemsInUpload, setAllItemsInUpload] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [customFieldsMetaData, setCustomFieldsMetaData] = useState([])
    const [customFieldsKeyValues, setCustomFieldsKeyValues] = useState([])
    const [secondaryNames, setSecondaryNames] = useState({})
    const isAddMode = !id;
    const initialValues = {
        CustomFields: {
            Connect: "",
        },
        RunningMap: "",
        TalkDistance: "800",
        WorldDescription: "",
        WorldFriendlyName: "",
        WorldID: "",
        WorldImageURL: "",
        WorldStatus: "",
        isVisible: "",
        PakDownloadsRequired: [" "],
        DownloadsRequired: [" "],
        CustomAdmins: [" "],
        SecondaryNames: {}
    };
    const formikRef = useRef();

    const validationSchema = Yup.object().shape({
        WorldID: Yup.string()
            .required('World Id is required'),
        WorldFriendlyName: Yup.string()
            .required('World Name is required'),
    });

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        console.log(65)
        setIsLoading(true)
        accountService.getGroupsData().then(x => {
            setGroups(x.Items)
            formikRef.current.setFieldValue('WorldStatus', x.Items[0].name, false)
            setIsLoading(false)
        });

    }, []);

    useEffect(() => {
        console.log('29032024 secondaryNames 77 ', secondaryNames)
    }, [secondaryNames])
    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createWorlds(fields, setSubmitting);
        } else {
            updateWorlds(id, fields, setSubmitting);
        }
    }

    const [downloadsValidation, setDownloadsValidation] = useState(false);
    const [customAdminValidation, setCustomAdminValidation] = useState(false);
    const [pakDownloadsValidation, setPakDownloadsValidation] = useState(false);
    function createWorlds(fields, setSubmitting) {
        if (checkEmptyValuesForDownloadsRequired() && checkEmptyValuesForPakDownloads() && checkEmptyValuesForCustomAdmin()) {
            setDownloadsValidation(false)
            setCustomAdminValidation(false)
            setPakDownloadsValidation(false)
            fields.PakDownloadsRequired = pakDownloads
            fields.CustomAdmins = customAdmin
            fields.DownloadsRequired = downloads
            fields.WorldFriendlyNameSC = fields.WorldFriendlyName?.toLowerCase()
            console.log("New fields: 29032024 ", JSON.stringify(fields))
            accountService.createWorlds(fields)
                .then(() => {
                    alertService.success('World added successfully', { keepAfterRouteChange: true });
                    setTimeout(() => {
                        history.push(`/edit-world/${fields.WorldID}`);
                        window.location.reload()
                    }, 2000);
                })
                .catch(error => {
                    console.log(error)
                    setSubmitting(false);
                    alertService.error(error);
                });
            console.log("called createWorlds")
        } else {
            setSubmitting(false)
        }
        if (checkEmptyValuesForDownloadsRequired() == false) {
            setDownloadsValidation(true)
        }
        if (checkEmptyValuesForPakDownloads() == false) {
            setPakDownloadsValidation(true)
        }
        if (checkEmptyValuesForCustomAdmin() == false) {
            setCustomAdminValidation(true)
        }

    }

    function checkEmptyValuesForDownloadsRequired() {
        if (downloads.length > 0) {
            return true
        } else {
            return false
        }
    }
    function checkEmptyValuesForPakDownloads() {
        if (pakDownloads.length > 0) {
            return true
        } else {
            return false
        }
    }
    function checkEmptyValuesForCustomAdmin() {
        if (customAdmin.length > 0) {
            return true
        } else {
            return false
        }
    }
    function updateWorlds(id, fields, setSubmitting) {
        setSubmitting(false)
        console.log("147  updateWorlds cod1", formikRef.current.values.CustomFields, customFieldsMetaData, customFieldStructure)

        // check if required fields exist 
        const CustomFields = formikRef.current.values.CustomFields
        for (const customField in CustomFields) {
            console.log('146 updateWorlds dynamic poster', customField.includes(','), customField.trim().length, customField, customField.length, CustomFields[customField], CustomFields[customField].startsWith(`<<${customField}>>`))
            console.log('delete CustomFields[customField]::: ', customField, typeof customField)
            if (customField.trim().length === 0 || !customField || customField.includes(',')) {
                delete CustomFields[customField]
                continue
            }
            if (CustomFields[customField].includes(`<<${customField}>>`)) {
                CustomFields[customField] = CustomFields[customField].split(`<<${customField}>>`)[1]
                continue
            }
            if (CustomFields[customField].startsWith(`<<https://`) && CustomFields[customField].endsWith(`>>undefined`)) {
                CustomFields[customField] = CustomFields[customField].split(`<<`)[1].split('>>')[0]
                continue
            }
            if (CustomFields[customField].includes(`Target1`)) {
                CustomFields[customField] = CustomFields[customField].replace(/Target\d/g, 'Target')
                console.log('updateWorlds Target1 codx ======>', CustomFields[customField])

            }
            if (CustomFields[customField].includes(`Options1`)) {
                CustomFields[customField] = CustomFields[customField].replace(/Options\d/g, 'Options')
                CustomFields[customField] = CustomFields[customField].replace(/Questions\d/g, 'Questions')
                CustomFields[customField] = CustomFields[customField].replace(/Correct\d/g, 'Correct')
                console.log('updateWorlds Target1 codx ======>', CustomFields[customField])

            }
            const fieldType = customField.split('_').filter(e => e.startsWith('playlist')).pop()
            const metaData = customFieldsMetaData.find(meta => meta.fieldName === fieldType)
            console.log("154 updateWorlds cod1", metaData)
            if (!metaData) continue
            const requiredFields = [...Object.keys(metaData.splitFields), 'ElementType']
            console.log("157 updateWorlds cod1", requiredFields)
            let customFieldValue = CustomFields[customField]
            requiredFields.forEach(requiredField => {

                if (CustomFields[customField].includes(requiredField))
                    return
                let requiredFieldSubString = `<<${requiredField}>>`
                if (requiredField === 'PlayType') requiredFieldSubString += 'Stream'
                if (requiredField === 'ElementType') requiredFieldSubString = metaData.prefix
                console.log("167 updateWorlds cod1", requiredFieldSubString)
                customFieldValue = requiredFieldSubString + customFieldValue
            })

            customFieldValue = customFieldValue.replace(/<<Autoplay>>(true\b|.*?<<|.*?)$/, (match, p1) => {
                if (p1 === "true" || p1.startsWith("true<<")) {
                    return match; // No replacement if "true" follows Autoplay>>
                } else if (p1.startsWith("<<")) {
                    return p1;  // Replace with "<<" if it ends with "<<"
                } else {
                    return "";    // Replace with "" if there's no "<<"
                }
            })
            CustomFields[customField] = customFieldValue
        }
        console.log("181 updateWorlds final customfields codx =====>", CustomFields)
        // match the type

        // check if they exist in the string
        // add them


        if (checkEmptyValuesForDownloadsRequired() && checkEmptyValuesForPakDownloads() && checkEmptyValuesForCustomAdmin()) {
            fields.PakDownloadsRequired = pakDownloads
            fields.CustomAdmins = customAdmin
            fields.DownloadsRequired = downloads
            fields.WorldFriendlyNameSC = fields.WorldFriendlyName.toLowerCase()
            // var object = formikRef.current.values.CustomFields.reduce(
            //     (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

            fields.CustomFields = CustomFields
            fields.SecondaryNames = secondaryNames
            console.log("updateWorlds payload:::: ", JSON.stringify(fields))
            // return
            accountService.updateWorlds(id, fields)
                .then(() => {
                    alertService.success('Update successful', { keepAfterRouteChange: true });
                    // window.scrollTo(0, 0)
                    setTimeout(() => {
                        // history.push('/worlds');
                    }, 2000);
                })
                .catch(error => {
                    setSubmitting(false);
                    alertService.error(error);
                });
        } else {
            setSubmitting(false)
        }
    }


    const deleteWorld = (e, world) => {
        e.preventDefault();
        if (window.confirm('Are sure you want to delete this World. This is an irreversible action?') == true) {
            // //console.log('deleted World', world)
            accountService.deleteWorld(world).then(res => {
                // //console.log("res", res)
                history.push('/worlds');
            })
        } else {
            // //console.log('cancelled', world)
        }

    }

    const fileUpload = (event, type) => {
        setIsLoading(true)
        const files = event.target.files[0];
        const allFiles = event.target.files;
        let myFiles = Array.from(allFiles);

        if (type == 'world') {
            accountService
                .uploadFile(files)
                .then((data) => {

                    formikRef.current.setFieldValue('WorldImageURL', data.url, false)
                    setIsLoading(false)
                    // //console.log("formikRef.current",formikRef.current)
                    // setFieldsForUpdatingUser(prevState => ({
                    //   ...prevState,
                    //   WorldImageURL: data.url
                    // }))
                });
        }
        const uploadingFiles = []
        if (type == 'items') {
            myFiles.map(items =>
                accountService
                    .uploadFile(items)
                    .then((data) => {
                        setAllItemsInUpload(oldArray => [...oldArray, data.url]);
                        setIsLoading(false)
                    })
            )

        }
    }

    const changeHandler = (data) => {
        const { name, value } = data;
        console.log('updateWorlds 279', name, value)
        formikRef.current.setFieldValue(name, value, false)
    }

    useEffect(() => { console.log(283) }, [formikRef.current && formikRef.current.values])
    const changeDropdown = (e) => {
        formikRef.current.setFieldValue('RunningMap', e.target.value, false)
        // //console.log("e", e.target.value)

    }
    const changeDropdownWorldStatus = (e) => {
        localStorage.setItem('WorldStatus', e.target.value)
        formikRef.current.setFieldValue('WorldStatus', e.target.value, false)
        // //console.log("e", e.target.value)

    }
    const [maps, setMaps] = useState([]);

    // [
    //     {
    //         key:'MoveTo', 
    //         value:{
    //             Target: "worldoffegaschmitt_fes",
    //             Thumbnail: "https://wisvrtoolboxin.s3.ap-south-1.amazonaws.com/downloads/ServerImage/Buzzer1.jpg"
    //         }
    //     }
    // ]
    const changeCustomFields = (e, CF, mapData) => {
        console.log("changeCustomFields 276 cod1", mapData)
        //console.log("CF in change custom Fields",CF)
        //  //console.log("data in add world",e.target.value)
        const { name, value } = e.target;
        //setTextName(e.target.value)
        // useState([{key:'', value:[]}])
        // setFilledCustomFields(prevState => ({...prevState, [name]:value}))
        setFilledCustomFields(prevState => {
            console.log("changeCustomFields cod1 setFilledCustomFields()", prevState, mapData, name, value)
            const arr = [
                {
                    [mapData]: { ...prevState.value, [name]: value }
                }
            ]
            if (prevState.length > 0) {
                prevState.map(newState => {
                    if (newState.MapName == arr[0].MapName) {
                        return { ...newState, ...arr[0] }
                    }
                })
            }
            // //console.log("PrevState:", prevState)
            ////console.log("New arr", arr)
            const merged = _.merge(prevState, arr)
            console.log("changeCustomFields cod1 setFilledCustomFields 300", merged[0])
            return [merged[0]]
        })

    }

    useEffect(() => {
        console.log(337)
        console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 276 customFieldStructure cod1", customFieldStructure)
        if (filledCustomFields.length > 0 && !isAddMode) {
            console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 280 filledCustomFields cod1", filledCustomFields[0])
            const updatedFilledCustomFieldsObject = _.cloneDeep(filledCustomFields[0])
            if (filledCustomFields.length > 1) {
                const newlyAddedFields = filledCustomFields.slice(1)
                console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 280.5 filledCustomFields cod1", newlyAddedFields)
                newlyAddedFields.forEach(field => {
                    const key = Array.isArray(field[0]) ? field[0][0] : field[0]
                    updatedFilledCustomFieldsObject[key] = field[1]
                    console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 281 filledCustomFields cod1", key)
                })
            }
            const newArr = Object.entries(updatedFilledCustomFieldsObject).map(items => ({ key: items[0], value: items[1] }))
            // let oldObj = {};
            let newo = [];
            console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 281 newArr", newArr)
            newArr.forEach(fcf => {
                let arr = [];
                for (var key in fcf.value) {
                    if (fcf.value.hasOwnProperty(key)) {
                        arr.push('<<' + key + '>>' + fcf.value[key]);
                    }
                };
                var result = arr.join(',').replace(/,/g, "");
                console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 364 result", result)
                newo = [...newo, { [fcf.key]: result }]
                //  oldObj = {...formikRef.current.values.CustomFields, [fcf.key]: result}
                //console.log("fcf",result)
                //console.log("custom Field values",formikRef.current.values)

            })
            console.log("addcycle addandelete updateWorlds uF1 setFilledCustomFields::: addWorld 371 filledCustomFields cod1", newo)
            if (newo.length > 0) {
                formikRef.current.setFieldValue('CustomFields', Object.assign(...newo), false)
            } else {
                formikRef.current.setFieldValue('CustomFields', {}, false)
            }
            //console.log("fcf",filledCustomFields)

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filledCustomFields])


    // useEffect(() => {
    //     setIsLoading(true)
    //     const arr = []
    //     const customF = []


    //     // accountService.getAllMaps().then(x => {
    //     //   setMaps(x.Items)
    //     //   accountService.getByWorldName(id).then(world => {
    //     //     const mapSelected = x.Items.filter(map=> map.mapName == world.Item.RunningMap)
    //     // //    //console.log("cf",mapSelected[0].CF)
    //     // //    Object.entries(mapSelected[0].CF).map((key,value)=>{
    //     // //        //console.log("key",key)
    //     // //        //console.log("value",value)
    //     // //    })
    //     //    for (const [key, value] of Object.entries(mapSelected[0].CF)) {

    //     //         arr.push({key:key, value:value})

    //     //     }
    //     //     // //console.log("value",arr)
    //     //     arr.map(cf=>{
    //     //         accountService.getCustomFields(cf.value).then(fields => {
    //     //             setCustomFields(prevState => {
    //     //                 return [...prevState, {...fields.Item, 'mapName':cf.key}]
    //     //             })
    //     //         })
    //     //     })

    //     //     // .map(customFields=>{

    //     //     //     // accountService.getCustomFields(customFields).then(fields => {
    //     //     //     //     //console.log("fields",fields)
    //     //     //     // })
    //     //     // })


    //     //   })
    //       setIsLoading(false)
    //     // });
    // }, [formikRef.current]);

    //  useEffect(()=>{
    //            if(customFieldStructure.length>0){
    //              ////console.log("customFieldStructure",customFieldStructure)
    //            }

    //         }, [customFieldStructure])
    useEffect(() => {
        if (!isAddMode && formikRef.current) {
            console.log(426)
            // get user and set form fields
            let allCustomFields = []
            console.log('29032024 ', id)
            accountService.getByWorldName(id).then(world => {
                console.log('29032024 ', world)
                const fields = ['WorldID', 'RunningMap', 'TalkDistance', 'WorldDescription', 'WorldFriendlyName', 'WorldImageURL', 'WorldStatus',
                    'isVisible', 'PakDownloadsRequired', 'DownloadsRequired', 'CustomAdmins', 'CustomFields', 'SecondaryNames'];
                fields.forEach(field => {
                    if (field === 'SecondaryNames') {
                        initialValues.SecondaryNames = world.Item[field]
                        setSecondaryNames(world.Item[field])
                        console.log('setSecondaryNames 29032024', world.Item[field])
                    }
                    if (field === 'WorldStatus') localStorage.setItem('WorldStatus', world.Item[field])
                    console.log('updateWorlds 449', fields, world.Item[field])
                    formikRef.current.setFieldValue(field, world.Item[field], false)
                });
                // //console.log("custom fields here", formikRef.current.values.CustomFields)
                Object.entries(formikRef.current.values.CustomFields).map(customFieldsData => {

                    // console.log("400, cod1 useEffect addWorld", customFieldsData)
                    // console.log("400, cod1 useEffect addWorld", customFieldsData)
                    allCustomFields.push({
                        "key": customFieldsData[0], "value":
                            !(customFieldsData[1].includes('<<') && customFieldsData[1].includes('>>')) ?
                                `<<${customFieldsData[0]}>>${customFieldsData[1]}` : customFieldsData[1]
                    })
                }

                )

                allCustomFields = allCustomFields.map(customField => {
                    let occurrences = 0
                    console.log('allCustomFields codx 456 :forEach', customField)
                    const occurrencesArray = customField.value.match(/Target/g)

                    console.log('allCustomFields codx 458 :', occurrencesArray)
                    if (Array.isArray(occurrencesArray) && occurrencesArray.length > 1) {
                        occurrences = occurrencesArray.length
                        console.log('allCustomFields codx 460 :', occurrences)
                        let originalString = customField.value
                        for (let i = 1; i <= occurrences; i++) {
                            const searchString = "Target";
                            const replacementString = "Target" + (i === 1 ? '' : i - 1);
                            originalString = replaceNthOccurrence(originalString, searchString, replacementString, i);
                            console.log('allCustomFields codx 464 :', originalString)
                        }
                        console.log('allCustomFields codx 468 :', { key: customField.key, value: originalString })
                        customField = { key: customField.key, value: originalString }
                    }
                    return customField
                })

                allCustomFields = allCustomFields.map(customField => {
                    let occurrences = 0
                    console.log('allCustomFields codx Options1:', customField)
                    const occurrencesArray = customField.value.match(/Options/g)

                    console.log('allCustomFields codx Options2:', occurrencesArray)
                    if (Array.isArray(occurrencesArray) && occurrencesArray.length > 0) {
                        occurrences = occurrencesArray.length
                        console.log('allCustomFields codx Options3:', occurrences)
                        let originalString = customField.value
                        for (let i = 0; i <= occurrences; i++) {
                            const searchString = "Options";
                            const replacementString = "Options" + (i === 0 ? '' : i);
                            if (i === 0) continue
                            originalString = replaceNthOccurrence(originalString, searchString, replacementString, i + 1);
                            console.log('allCustomFields codx Options4:', originalString)
                        }
                        console.log('allCustomFields codx Options5:', { key: customField.key, value: originalString })
                        customField = { key: customField.key, value: originalString }
                    }
                    return customField
                })

                allCustomFields = allCustomFields.map(customField => {
                    let occurrences = 0
                    console.log('allCustomFields codx 456 :forEach', customField)
                    const occurrencesArray = customField.value.match(/Correct/g)

                    console.log('allCustomFields codx 458 :', occurrencesArray)
                    if (Array.isArray(occurrencesArray) && occurrencesArray.length > 0) {
                        occurrences = occurrencesArray.length
                        console.log('allCustomFields codx 460 :', occurrences)
                        let originalString = customField.value
                        for (let i = 0; i < occurrences; i++) {
                            const searchString = "Correct";
                            const replacementString = "Correct" + (i === 0 ? '' : i);
                            if (i === 0) continue
                            originalString = replaceNthOccurrence(originalString, searchString, replacementString, i + 1);
                            console.log('allCustomFields codx 464 :', originalString)
                        }
                        console.log('allCustomFields codx 468 :', { key: customField.key, value: originalString })
                        customField = { key: customField.key, value: originalString }
                    }
                    return customField
                })

                console.log("allCustomFields codx 473 :", allCustomFields)
                setCustomFieldsKeyValues(allCustomFields)

                const filteredArray = allCustomFields.map(customKeyVal => {
                    if (customKeyVal.value != null) {
                        let withSplit = customKeyVal.value.split('<<')
                        const d = withSplit.map(splitData => {
                            ////console.log("withSplit",splitData.split('>>'))
                            if (splitData.length > 1) {
                                const mappingSplitData = splitData.split('>>')

                                return mappingSplitData
                            }

                        })

                        const data = d.filter(x => x !== undefined)
                        if (data.length > 0) {
                            const newObj = data.map(nd => {
                                return {
                                    [nd[0]]: nd[1]
                                }
                            })
                            const flattenObj = Object.assign(...newObj)
                            const mapData = { [customKeyVal.key]: flattenObj }
                            return mapData;
                        } else {
                            const mapData = {}
                            return mapData;
                        }
                    }




                    //    //console.log("data check here", flattenObj)
                    // const mapData = {'key':customKeyVal.key, 'value':d.filter(x => x !== undefined)}


                    //     //console.log("data check here mapped Data", mapData)

                })
                // var newObj = filteredArray.reduce(
                //     (obj, item) => Object.assign(obj, { [item]: item.value }), {});
                const newFiltered = Object.assign({}, ...filteredArray)
                setFilledCustomFields([newFiltered])
                console.log("378 useEffect addWorlds cod1 setFilledCustomFields()", newFiltered)
                //console.log("filteredArray",newFiltered)
                ////console.log("allCustomFields", allCustomFields)
                setDownloads(formikRef.current.values.DownloadsRequired)
                setPakDownloads(formikRef.current.values.PakDownloadsRequired)
                setCustomAdmin(formikRef.current.values.CustomAdmins)

                accountService.getByMapName(formikRef.current.values.RunningMap).then(allMapsRelatedToWorld => {
                    if (Object.entries(allMapsRelatedToWorld).length > 0) {
                        const worldItemSecondaryNames = world.Item.SecondaryNames
                        if (worldItemSecondaryNames === undefined || (typeof worldItemSecondaryNames === 'object' && Object.keys(worldItemSecondaryNames) === 0)) {
                            const secondaryNameObj = {}
                            Object.keys(allMapsRelatedToWorld.Item.CF).map(cfKeys => {
                                secondaryNameObj[cfKeys] = ''
                            })
                            formikRef.current.setFieldValue('SecondaryNames', secondaryNameObj, false)
                            initialValues.SecondaryNames = secondaryNameObj
                            setSecondaryNames(secondaryNameObj)
                            console.log('secondaryNameObj 29032024', secondaryNameObj)
                        }
                        Object.entries(allMapsRelatedToWorld.Item.CF).map(mapsToCF => {
                            accountService.getCustomFields(mapsToCF[1]).then(fields => {
                                const fieldsItem = { ...fields.Item, 'mapName': mapsToCF[0] }
                                // if ('splitRepeat' in fieldsItem && Object.keys(fieldsItem.splitRepeat).length) {
                                //     fieldsItem.splitRepeat = newFiltered[fieldsItem.mapName]
                                //     console.log('splitRepeat codx =====>', fieldsItem)

                                // }
                                setCustomFieldsMetaData((prevState => {
                                    return [...prevState, fieldsItem]
                                }))
                                setCustomFieldStructure(prevState => {
                                    return [...prevState, fieldsItem]
                                })
                            })
                        })
                    }

                });

            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAddMode && formikRef.current) {
            // get user and set form fields
            const fields = ['WorldID', 'RunningMap', 'TalkDistance', 'WorldDescription', 'WorldFriendlyName', 'WorldImageURL',
                'WorldStatus', 'isVisible', 'PakDownloadsRequired', 'DownloadsRequired', 'CustomAdmins', 'CustomFields'];
            fields.forEach(field => formikRef.current.setFieldValue(field, initialValues[field], false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddMode]);


    const changeKeyValue = (e, type, key) => {
        //console.log("value", type,': ',e.target.value, "---key: ", key)
        if (type == 'key') {
            customFieldsKeyValues[key].key = e.target.value
            customFieldsKeyValues[key].value = customFieldsKeyValues[key].value
            setCustomFieldsKeyValues(customFieldsKeyValues)
        }
        if (type == 'value') {
            customFieldsKeyValues[key].key = customFieldsKeyValues[key].key
            customFieldsKeyValues[key].value = e.target.value

            setCustomFieldsKeyValues(customFieldsKeyValues)
        }
        formikRef.current.setFieldValue('CustomFields', customFieldsKeyValues, false)
    }

    const addCustomFields = (e) => {
        e.preventDefault();
        const newCustomField = { key: "", value: "" }
        setCustomFieldsKeyValues(prevState => [...prevState, newCustomField])
    }

    const deleteCustomField = (e, key) => {
        e.preventDefault();
        customFieldsKeyValues.splice(key, 1);
        setCustomFieldsKeyValues(customFieldsKeyValues)
        formikRef.current.setFieldValue('CustomFields', customFieldsKeyValues, false)
    }
    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="text-white mr-2">

                    </span> {isAddMode ? 'Add World' : 'Edit World'} </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">

                        </li>
                    </ul>
                </nav>
            </div>

            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <Alert />
                        <div className="table-responsive" style={{ overflow: 'hidden' }}>

                            <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={formikRef} onSubmit={onSubmit}>
                                {({ errors, touched, isSubmitting, setFieldValue }) => {


                                    return (
                                        <div>

                                            {<Form>

                                                {((user && (user.Item.Abilities.isGlobalAdmin == 'true')) || isAddMode) && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label title='Unique Id for specific World'>World Id </label>
                                                            <Field name="WorldID" type="text" disabled={!isAddMode} className={'form-control' + (errors.WorldID && touched.WorldID ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="WorldID" component="div" className="invalid-feedback" />
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <label title='Group ID / Group Name'>Group ID</label>
                                                            {/* <Field name="WorldStatus" type="text"  className={'form-control' + (errors.WorldStatus && touched.WorldStatus ? ' is-invalid' : '')} />
                                <ErrorMessage name="WorldStatus" component="div" className="invalid-feedback" /> */}

                                                            {groups.length > 0 &&
                                                                <Field name="WorldStatus" as="select" className={'form-control' + (errors.WorldStatus && touched.WorldStatus ? ' is-invalid' : '')} onChange={e => changeDropdownWorldStatus(e)}>
                                                                    {groups.map(group => (
                                                                        <option value={group.name} key={group.name}>{group.ClientName}</option>
                                                                    ))}
                                                                </Field>

                                                            }
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <label title='Engine Floor Map'>Running Map</label>
                                                            <Field name="RunningMap" type="text" className={'form-control' + (errors.RunningMap && touched.RunningMap ? ' is-invalid' : '')} />

                                                            {id && maps.length > 0 &&
                                                                <Field name="RunningMap" as="select" className={'form-control' + (errors.RunningMap && touched.RunningMap ? ' is-invalid' : '')} onChange={e => changeDropdown(e)}>
                                                                    {maps.map(map => (
                                                                        <option value={map.mapName} key={map.mapName}>{map.WorldName}</option>
                                                                    ))}
                                                                </Field>

                                                            }
                                                            <ErrorMessage name="RunningMap" component="div" className="invalid-feedback" />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label title='Check/Uncheck to make world Active/In-active in toolbox'>Visible (true/false)</label>
                                                            <Field type="text" className={'form-control'} name="isVisible" />
                                                            {/* <Field name="isVisible" as="select" className={'form-control'}>
                                    <option value=""></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </Field> */}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-row">

                                                    <div className="form-group col-md-6">
                                                        <label title='World Name to be Displayed in toolbox'>Display Name</label>
                                                        <Field name="WorldFriendlyName" type="text" className={'form-control' + (errors.WorldFriendlyName && touched.WorldFriendlyName ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="WorldFriendlyName" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label title='About World'>Description</label>
                                                        <Field name="WorldDescription" type="text" className={'form-control' + (errors.WorldDescription && touched.WorldDescription ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="WorldDescription" component="div" className="invalid-feedback" />
                                                    </div>

                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label title='World Thumbnail to be displayed'>World Thumbnail</label>
                                                        <Field name="WorldImageURL" type="file" value={undefined} className={'form-control'} onChange={e => fileUpload(e, 'world')} single="true" />
                                                        {formikRef.current && formikRef.current.values.WorldImageURL && (
                                                            <div className="image-area">
                                                                <img src={formikRef.current && formikRef.current.values.WorldImageURL} width="100" height="100" />
                                                                <span className="remove-image" style={{ "display": "inline" }}
                                                                    onClick={e => { changeHandler({ name: 'WorldImageURL', value: undefined }) }}>&#215;</span>
                                                            </div>
                                                        )}
                                                        <ErrorMessage name="WorldImageURL" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label title='Distance till which the User can hear the surrounding sounds'>Conversational Distance in cm</label>
                                                        <Field name="TalkDistance" type="number" className={'form-control' + (errors.TalkDistance && touched.TalkDistance ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="TalkDistance" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>


                                                <div className="form-row">

                                                    <div className="form-group col-md-4">
                                                        <div className="card bg-light">
                                                            <div className="card-body">
                                                                <div className='row'>
                                                                    <label> Downloads Required</label>
                                                                    {formikRef.current && (formikRef.current.values.DownloadsRequired || formikRef.current.values.DownloadsRequired == null) &&

                                                                        <DownloadsArray items={formikRef.current && formikRef.current.values.DownloadsRequired} setFieldValue={setFieldValue} type={"PakDownloadsRequired"} setDownloads={setDownloads} setDownloadsValidation={setDownloadsValidation} />}
                                                                </div>
                                                            </div></div>
                                                        {
                                                            downloadsValidation && <div className='invalid-world-items'>Downloads cannot be empty</div>
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <div className="card bg-light">
                                                            <div className="card-body">
                                                                <div className='row'>
                                                                    <label> Custom Admins</label>
                                                                    {formikRef.current && formikRef.current.values.CustomAdmins &&

                                                                        <CustomAdminsArray items={formikRef.current && formikRef.current.values.CustomAdmins} setFieldValue={setFieldValue} type={"PakDownloadsRequired"} setCustomAdmin={setCustomAdmin} setCustomAdminValidation={setCustomAdminValidation} />}
                                                                </div></div></div>
                                                        {
                                                            customAdminValidation && <div className='invalid-world-items'>Custom Admins cannot be empty</div>
                                                        }</div>
                                                    <div className="form-group col-md-4">
                                                        <div className="card bg-light">
                                                            <div className="card-body">
                                                                <div className='row'>
                                                                    <label>Add Pak Downloads</label>
                                                                    {formikRef.current && formikRef.current.values.PakDownloadsRequired &&

                                                                        <PakDownloadsArray items={formikRef.current && formikRef.current.values.PakDownloadsRequired} setFieldValue={setFieldValue} type={"PakDownloadsRequired"} setPakDownloads={setPakDownloads} setPakDownloadsValidation={setPakDownloadsValidation} />}
                                                                </div>
                                                            </div></div>
                                                        {
                                                            pakDownloadsValidation && <div className='invalid-world-items'>Pak Downloads Required cannot be empty</div>
                                                        }</div>
                                                </div>

                                                <div className="form-group col-12">
                                                    {/* <div className="form-group col-4">
                                <label>Phone Number</label>
                                <Field name="phoneNumber" type="number" className={'form-control'} />
                            </div> */}
                                                    <div className="card bg-light">


                                                        {/* <div className="card-body" style={{"border": "2px solid red"}}>
                                    {
                                        customFieldsKeyValues.map((customFieldsKeyValue, key) => (
                                            <>
                                                <div className="row" key={key}>
                                                    <div className="col-5">
                                                    <Field  type="text" className={'form-control'}  name={customFieldsKeyValue['key']} value={customFieldsKeyValue['key']} onChange={e=>changeKeyValue(e, 'key', key)}/>
                                                    </div>
                                                    <div className="col-5">
                                                    <Field  type="text" className={'form-control'}  name={customFieldsKeyValue['value']} value={customFieldsKeyValue['value']} onChange={e=>changeKeyValue(e, 'value', key)}/>
                                                    </div>
                                                    <div className="col-2">
                                                    <button onClick={e=> deleteCustomField(e, key)} className="btn btn-danger"> Delete</button>
                                                    </div>
                                                </div> 
                                            </>
                                        ))
                                    }
                                    <button onClick={e=> addCustomFields(e)} className="btn btn-primary"> New Field</button>
                                </div> */}
                                                    </div>

                                                </div>

                                                <div className="form-group col-mod-12">
                                                    <div className="card bg-light">

                                                        <CustomFields isLoading={isLoading} customFieldsMetaData={customFieldsMetaData} setCustomFieldsMetaData={setCustomFieldsMetaData}
                                                            setIsLoading={setIsLoading} customFields={customFieldStructure} formikRef={formikRef}
                                                            changeCustomFields={changeCustomFields} setFilledCustomFields={setFilledCustomFields} filledCustomFields={filledCustomFields}
                                                            isAddMode={isAddMode} secondaryNames={secondaryNames} setSecondaryNames={setSecondaryNames} />
                                                    </div>
                                                </div>
                                                {/* <div className="form-group col-12">
                        <div className="card bg-light">
                                <div className="card-body">
                                <label>Upload to get link</label>
                                <Field type="file" value={undefined}  className={'form-control'} onChange={e => fileUpload(e, 'items')} multiple="true"/>
                                {isLoading && 
                                    <div className='col-12 text-center'>
                                      <LoadingIndicator />
                                    </div>
                                }
                                {allItemsInUpload.length > 0 && <ul>{allItemsInUpload.map(items=>(<li>{items}</li>))}</ul>}
                                </div> </div>
                                 </div> */}
                                                <div className="form-group">
                                                    <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                        Save
                                                    </button>
                                                    <Link to={isAddMode ? '.' : '..'} className="btn text-avatar">Cancel</Link>
                                                    {id && (user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true') && <button onClick={(e) => deleteWorld(e, formikRef.current.values.WorldID)} className="btn btn-danger">
                                                        Delete
                                                    </button>}
                                                </div>
                                            </Form>}
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddWorld;