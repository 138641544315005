import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';
import { Close } from '@mui/icons-material'
const WorldIdCustom = ({ formikRef, CF, changeCustomFields, innerKeyName, mapName, filledCustomFields, deleteField }) => {
    console.log("filledCustomFields  world Id", CF[0], '   ', formikRef && formikRef.current ? formikRef.current.values.WorldStatus : localStorage.getItem('WorldStatus'), innerKeyName)
    const [worlds, setWorlds] = useState([]);
    let currentValue = CF[0] || "DEFAULT";

    useEffect(() => {
        if (localStorage.getItem('WorldStatus'))
            accountService.searchWorldsForGroupName('', localStorage.getItem('WorldStatus')).then(x => {
                setWorlds(x.Items)
            });
    }, []);

    const dropdownChangeHandler = (e) => {
        const { name, value } = e.target
        changeCustomFields({ target: { name: name, value: value } }, CF[0], innerKeyName)
    }
    // useEffect(()=>{
    //     if(textVal !=''){
    //         changeCustomFields({target:{name:customName['key'], value:textVal}}, mapName)
    //     }

    // },[textVal != ''])
    return (
        <>
            <div className="row">
                <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                    <label>
                        {CF[0]}
                    </label>
                </div>
                <div className="col-4">
                    {worlds.length > 0 &&
                        <div className="d-flex align-items-center justify-content-end">
                            <select className="form-control form-control-lg" id="worldId" name={CF[0]} value={filledCustomFields && filledCustomFields[1]} defaultValue={'DEFAULT'} onChange={dropdownChangeHandler}>
                                <option value="DEFAULT" disabled>Choose a World ...</option>
                                {

                                    worlds.map(dd => (
                                        <option name={dd.WorldID} value={dd.WorldID} key={dd.WorldID}>{dd.WorldFriendlyName}</option>
                                    ))

                                }
                            </select><Close style={{ display: 'inline', fontSize: '30px' }} onClick={(e) => deleteField(e, CF[0], innerKeyName)} />
                        </div>
                    }
                </div>
            </div>

        </>
    )
}
export default WorldIdCustom;